import { Component, OnInit } from "@angular/core";

declare const $: any;
@Component({
  selector: "app-data-policy",
  templateUrl: "./data-policy.component.html",
  styleUrls: ["./data-policy.component.css"],
})
export class DataPolicyComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {
    $("#selectLanguage").selectpicker();
    $("#selectLanguage").selectpicker("val", "Español");
    $("#selectLanguage").on(
      "changed.bs.select",
      function (e, clickedIndex, isSelected, previousValue) {
        if (clickedIndex === 0) {
          document.getElementById("data-policy-EN").style.display = "none";
          document.getElementById("data-policy-ES").style.display = "";
        }
        if (clickedIndex === 1) {
          document.getElementById("data-policy-EN").style.display = "";
          document.getElementById("data-policy-ES").style.display = "none";
        }
      }
    );
  }
}
