import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  headers;

  constructor(private http: HttpClient) {
  }

  getCities(): Observable<any> {
    return this.http.get(environment.controlPanel + 'number-contacts').pipe(map(res => res));
  }
}
