import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
  baseUrl: string = environment.driverServer + 'view-position/';
  token: string;
  headers;

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Credentials', 'true');
    headers = headers.append('x-citytaxi-application-id', 'citytaxi');
    headers = headers.append('x-citytaxi-master-key', 'citytaxi-secret');
    this.headers = { headers };
    this.token = this.route.snapshot.paramMap.get('token');
  }

  get_Data(): Observable<any> {
    return this.httpClient
      .get(this.baseUrl + this.token, this.headers)
      .pipe(map(res => res));
  }
}
