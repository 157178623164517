import { Component, OnInit } from "@angular/core";

declare const $: any;

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.css"],
})
export class TermsComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    $("#btnTermUsers").on("click", () => {
      document.getElementById("termUsers").style.display = "";
      document.getElementById("termDriver").style.display = "none";
      document.getElementById("dataTreatment").style.display = "none";
    });
    $("#btnTermDriver").on("click", () => {
      document.getElementById("termUsers").style.display = "none";
      document.getElementById("termDriver").style.display = "";
      document.getElementById("dataTreatment").style.display = "none";
    });
    $("#btnDataTreatment").on("click", () => {
      document.getElementById("termUsers").style.display = "none";
      document.getElementById("termDriver").style.display = "none";
      document.getElementById("dataTreatment").style.display = "";
    });
  }
}
