import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgxPicaModule } from "ngx-pica";
import { ImageCropperModule } from "ngx-image-cropper";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { TermsComponent } from "./terms/terms.component";
import { ContactComponent } from "./contact/contact.component";
import { UsersComponent } from "./users/users.component";
import { DriversComponent } from "./drivers/drivers.component";
import { EnterprisesComponent } from "./enterprises/enterprises.component";
import { FooterComponent } from "./footer/footer.component";
import { SocialComponent } from "./social/social.component";
import { RestorePasswordComponent } from "./restore-password/restore-password.component";
import { UnsubscribeComponent } from "./unsubscribe/unsubscribe.component";
import { PreRegisterComponent } from "./taxia-plus/pre-register/pre-register.component";
import { HomeTaxiaPlusComponent } from "./taxia-plus/home/home.component";
import { ShareComponent } from "./share/share.component";
import { PositionComponent } from "./position/position.component";

import { environment } from "../environments/environment";
import { DataPolicyComponent } from "./data-policy/data-policy.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    TermsComponent,
    ContactComponent,
    UsersComponent,
    DriversComponent,
    EnterprisesComponent,
    FooterComponent,
    SocialComponent,
    RestorePasswordComponent,
    UnsubscribeComponent,
    PreRegisterComponent,
    HomeTaxiaPlusComponent,
    ShareComponent,
    PositionComponent,
    DataPolicyComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgxPicaModule,
    NgxGoogleAnalyticsModule.forRoot(environment.codeGA),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
