import { Component, OnInit} from '@angular/core';
import { PreRegisterService } from '@taxia-plus/pre-register/pre-register.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxPicaService } from 'ngx-pica';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

declare const alertify: any;
declare const window: any;
declare const $: any;
@Component({
  selector: 'app-pre-register',
  templateUrl: './pre-register.component.html',
  styleUrls: ['./pre-register.component.css'],
  providers: [PreRegisterService],
})
export class PreRegisterComponent implements OnInit {

  screenWidth = screen.width;
  imgselected = false;
  imgB64;
  categories;
  countries;
  cities;
  registerForm: FormGroup;
  submitted = false;
  confirmSendData = false;
  patternEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
  patternidCard = new RegExp('^[0-9]{8,10}$');
  patterPhone = new RegExp('^[0-9]{10,10}$');
  patternNit = new RegExp('^[0-9]{6,15}$');
  patterndv = new RegExp('^[0-9]{1,1}$');
  imageChangedEvent: any = '';

  constructor(
    private preRegisterService: PreRegisterService,
    private formBuilder: FormBuilder,
    private ngxPicaModule: NgxPicaService,
    protected $gaService: GoogleAnalyticsService
  ) { }

  get f() { return this.registerForm.controls; }

  ngOnInit() {
    $('#selectCategory').selectpicker();
    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      lastname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(65)]],
      idCard: ['', [Validators.required, Validators.pattern(this.patternidCard)]],
      cellPhone: ['', [Validators.required, Validators.pattern(this.patterPhone)]],
      nit: ['', [Validators.required, Validators.pattern(this.patternNit)]],
      establishment: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      email: ['', [Validators.required, Validators.pattern(this.patternEmail)]],
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      category: ['', [Validators.required]],
      dv: ['', [Validators.required, Validators.pattern(this.patterndv)]]
    });
    this.getCountries();
    this.getCities();
    this.getCategories();

    if (window.File && window.FileList && window.FileReader) {
      this.Init();
    } else {
      document.getElementById('file-drag').style.display = 'none';
    }
  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.imgB64 = event.base64;
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    if ((document.getElementById('file-upload') as HTMLInputElement).files.length !== 0) {
      const ciudad = this.registerForm.value.city;
      let categories = '';
      (this.registerForm.value.category).forEach(category => {
        categories += category.split('-')[1] + ',';
      });
      categories = categories.substring(0, categories.length - 1);
      alertify.confirm('Confirmación',
        'Has elegido <b>' + ciudad + '</b> como la ubicación de tu negocio y tus categorías son [<b>' + categories + '</b>] ¿Es correcto?',
        () => { this.sendData(); }, undefined).set('labels', { ok: 'Si', cancel: 'No' });
    } else {
      document.getElementById('errorImg').style.display = 'inline';
    }
  }

  getCategoriesSelected() {
    let categories = [];
      (this.registerForm.value.category).forEach(category => {
        categories.push(category.split('-')[0]);
      });
      return categories;
  }

  sendData() {
    this.confirmSendData = true;
    const data = {
      manager_name: this.registerForm.value.name,
      manager_lastname: this.registerForm.value.lastname,
      dni: this.registerForm.value.idCard + '',
      phone: this.registerForm.value.cellPhone,
      tax_id: this.registerForm.value.nit + '',
      business_name: this.registerForm.value.establishment,
      email: this.registerForm.value.email,
      image: this.imgB64,
      country: this.registerForm.value.country,
      domain: this.registerForm.value.city,
      categories: this.getCategoriesSelected(),
    };
    this.preRegisterService.getCheckDigit(this.registerForm.value.nit).subscribe(id => {
      if (id.digit === parseInt(this.registerForm.value.dv, 10)) {
        this.preRegisterService.saveRegister(data).subscribe(res => {
          this.$gaService.event('successful-registration', 'Taxia Plus');
          this.showSuccess(res.message);
          this.confirmSendData = false;
        }, err => {
          this.$gaService.event('error-registration', 'Taxia Plus');
          this.showErrorAlert(err.error.message || err.message);
          this.confirmSendData = false;
        });
      } else {
        alertify.set('notifier', 'position', 'top-center');
        alertify.error('El dígito de verificación no es correcto, por favor ingrese el dígito válido.');
        this.confirmSendData = false;
      }
    }, () => {
      alertify.set('notifier', 'position', 'top-center');
      alertify.error('No se pudo obtener el número de verificación.');
      this.confirmSendData = false;
    });
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imgB64 = reader.result;
    };
  }

  toDataURL(src, format, callback) {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = screen.height;
      canvas.width = screen.width;
      context.drawImage(canvas, 0, 0);
      const dataURL = canvas.toDataURL('image/' + format);
      callback(dataURL);
    };
    image.src = src;
  }

  getCategories() {
    this.preRegisterService.getCategories().subscribe(res => {
      this.categories = res.data;
      setTimeout(() => {
        $('#selectCategory').selectpicker('refresh');
      }, 1000);
    }, () => {
      this.showErrorAlert('Lo sentimos, las categorías no están disponibles. Inténtalo más tarde');
    });
  }

  getCountries() {
    this.countries = [
      {
        id: 1,
        name: 'Colombia'
      }
    ];
  }

  getCities() {
    this.preRegisterService.getCities().subscribe(res => {
      this.cities = res.data;
    }, () => {
      this.showErrorAlert('Lo sentimos, las ciudades no están disponibles. Inténtalo más tarde');
    });
  }

  Init() {
    const fileSelect = document.getElementById('file-upload');
    const fileDrag = document.getElementById('file-drag');
    fileSelect.addEventListener('change', this.fileSelectHandler.bind(this), false);
    const xhr = new XMLHttpRequest();
    if (xhr.upload) {
      fileDrag.addEventListener('dragover', this.fileDragHover, false);
      fileDrag.addEventListener('dragleave', this.fileDragHover, false);
      fileDrag.addEventListener('drop', (e) => {
        (document.querySelector('.image-url') as HTMLInputElement).files = e.dataTransfer.files; this.fileSelectHandler(e);
      }, false);
    }
  }

  fileDragHover(e) {
    const fileDrag = document.getElementById('file-drag');
    e.stopPropagation();
    e.preventDefault();
    fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
  }

  fileSelectHandler(e) {
    this.imgselected = true;
    const files = e.target.files || e.dataTransfer.files;
    this.fileDragHover(e);
    this.ngxPicaModule.compressImage(files[0], 1).subscribe(file => {
      this.parseFile(file);
    });
  }

  output(msg) {
    const m = document.getElementById('messages');
    m.innerHTML = msg;
  }

  confirmCrop() {
    document.getElementById('file-image').classList.remove('hidden');
    document.getElementById('imgCropper').classList.add('hidden');
  }

  editCrop() {
    if (this.imgB64) {
      document.getElementById('file-image').classList.add('hidden');
    }
    document.getElementById('imgCropper').classList.remove('hidden');
  }

  parseFile(file) {
    this.editCrop();
    document.getElementById('errorImg').style.display = 'none';
    this.getBase64(file);
    this.output(
      '<strong style="color: white">' + encodeURI(file.name) + '</strong>'
    );
  }

  showErrorAlert(mensaje) {
    alertify.alert('<div style="text-align:center"><h1 class= "oopsError">Oops...</h1></div>' +
      '<center><p class="texto-gray">' + mensaje + '</p></center>',
      () => {}).set({
        label: 'Ok',
        closable: false
      }).setHeader('Error');
  }

  showSuccess(mensaje) {
    alertify.alert(
      '<table>' +
      '<tr>' +
      '<td style="text-align:center; width:50%"><img src="../../assets/images/success.png" style="width: 100%;"></td>' +
      '<td style="max-width: 50px;">' +
      '<div style="text-align:center"><i class="fas fa-check-circle" style="color: #26a65b; font-size: 60px !important;"></i>' +
      '<p style="word-wrap:break-word; color: #26a65b; margin-top: 10px;">' + mensaje + '</p>' +
      '</div>' +
      '</td>' +
      '</tr>' +
      '</table>',
      () => {
        window.location.reload();
      }).set({
        label: 'Ok',
        closable: false
      }).setHeader('¡Tu registro ha sido exitoso!');
  }
}
