import { Component, OnInit } from '@angular/core';
import { PositionService } from '@position/position.service';
import { SocketService } from '@share/socket.service';
import * as mapboxgl from 'mapbox-gl';
declare const mapboxgl: any;

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.css'],
  providers: [PositionService, SocketService],
})
export class PositionComponent implements OnInit {

  data: any;
  style = 'mapbox://styles/mapbox/streets-v11';
  map;
  infoPosition;
  marker;
  markersCount = 0;

  constructor(private dataPosition: PositionService, private socketService: SocketService ) {}

  ngOnInit() {
    this.dataPosition.get_Data().subscribe(res => {
      this.data = res;
      this.infoPosition = {
        driver: res.driver,
        vehicle: res.vehicle,
      };
      if (!this.infoPosition.driver.photo) {
        this.infoPosition.driver.photo = '../../assets/images/profile.jpg';
      }
      this.createMap();
      this.socketEvents(this.data.device, this.map);
    }, () => {
      window.location.href = '/404';
    });
  }

  createMap() {
    const options = {
      container: 'map',
      style: this.style,
      zoom: 15,
      center: [ -75.517380, 5.068890 ], // Manizales
      disableDefaultUI: true,
      navigationControl: false,
      mapTypeControl: false
    };
    mapboxgl.accessToken = 'pk.eyJ1IjoiZGllZ29nb21leiIsImEiOiJjam5qaW1xeXMwenNsM2tueXFiZWZ1andiIn0.suJs58a1pOpL4c-rQzU2VA';
    this.map = new mapboxgl.Map(options);
  }

  socketEvents(device, map) {
    this.socketService.connect(device);
    this.socketService.subscribeToEvents()
    .subscribe((coordinates: any) => {
      this.markersCount++;
      this.printMarker(coordinates.longitude, coordinates.latitude, map);
    });
  }

  printMarker(lng, lat, map) {
    if (this.markersCount === 1) {
      map.flyTo({center: [lng, lat]});
    }
    if (this.marker) {
      this.marker.remove();
    }
    const el = document.createElement('div');
    el.className = 'marker';
    this.marker = new mapboxgl.Marker(el)
      .setLngLat([lng, lat])
      .addTo(map);
  }
}
