import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async closeMenu() {
    document.getElementById('opacityOpenMenu').classList.remove('opacityOpenMenu');

    for (let i = 0; i >= -100; i--) {
      document.getElementById('mySidenav').style.transform = 'translateX(' + i + '%)';
      await this.sleep(0.1);
    }
  }

  async openMenu() {
    document.getElementById('opacityOpenMenu').classList.add('opacityOpenMenu');

    for (let i = -100; i <= 0; i++) {
      document.getElementById('mySidenav').style.transform = 'translateX(' + i + '%)';
      await this.sleep(0.1);
    }
  }
}
