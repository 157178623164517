import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeTaxiaPlusComponent implements OnInit {

  screenWidth = screen.width;
  items;

  constructor(
    protected $gaService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.items = document.querySelectorAll('.accordion a');
    this.items.forEach((item) => {item.addEventListener('click', () => {
      item.classList.toggle('active');
      item.nextElementSibling.classList.toggle('active'); });
    });
  }

  openPage(url, actionGA, categoryGA) {
    this.$gaService.event(actionGA, categoryGA);
    window.open(url, '_parent');
  }

}
