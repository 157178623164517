import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket;

  constructor() {}

  connect(device) {
    const options = { transports: ['websocket'] };
    this.socket = io.connect(environment.apiLocations, options);
    const d: Date = new Date();
    const room = { user_email: `${device}${d}`, device_id: device };
    this.socket.on('connect', _ => {
      this.socket.emit('joinroom', room);
    });
    this.subscribeToEvents = this.subscribeToEvents.bind(this);
  }
  subscribeToEvents(): Subject<any> {
    const socketObservable = new Observable(observer => {
      this.socket.on('location', (data: any) => {
        const coordinates = {
          latitude: data.latitude,
          longitude: data.longitude
        };
        observer.next(coordinates);
      });
    });
    const socketObserver = {
      next: _ => {}
    };
    return Subject.create(socketObserver, socketObservable);
  }
}
