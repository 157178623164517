import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { url } from 'inspector';

declare const $: any;
declare const alertify: any;
declare const WOW: any;
declare const Waypoint: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [HomeService],
})
export class HomeComponent implements OnInit {

  cities;
  cityWhatsapp = [];
  whatsappNumbers = [];
  address;

  constructor(private homeService: HomeService, protected $gaService: GoogleAnalyticsService) { }

  ngOnInit() {

    this.getInfocities();

    $('.collapse').collapse({
      toggle: false
    });

    const wow = new WOW({
      boxClass: 'wow', // animated element css class (default is wow)
      animateClass: 'animated', // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: false, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
      scrollContainer: null // optional scroll container selector, otherwise use window
    });
    wow.init();

    const waypoint = new Waypoint({
      element: document.getElementById('indicators-container'),
      handler: () => {
        const items = document.getElementsByClassName('counter');
        Array.prototype.forEach.call(items, (item) => {
          $('#' + item.id).prop('Counter', 0).animate({
            Counter: $('#' + item.id).text()
          }, {
              duration: 8000,
              easing: 'swing',
              step: (now) => {
                $('#' + item.id).text(Math.ceil(now));
              }
            });
        });
        waypoint.disable();
      },
      offset: '60%'
    });

    $('.covervid-video').coverVid(1920, 1080);

    $('.slide-container').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
    });

    $('.test-popup-link').magnificPopup({
      type: 'iframe'
    });
  }

  arrowDown() {
    $('html, body').animate({
      scrollTop: $('#info-container').offset().top + 20
    }, 700);
  }

  closeModal() {
    $('#modal1').closeModal();
    $('.wrap-container').removeClass('blur');
  }

  openWhatsapp() {
    let city = '';
    let addressCopy;
    if (this.address !== undefined) {
      addressCopy = this.address;
      city = this.address[1];
      addressCopy = this.address[0].split(' a ');
      addressCopy = addressCopy[0].split('-');
      addressCopy = addressCopy[0] + '-';
    }
    this.alertMessagesAlertify(addressCopy, city);
  }
  openWebapp() {
    const url = `https://webapp.taxialife.com/`
    this.$gaService.event('Open-Webapp', 'Webapp');
    window.open(url, "_blank");
  }

  openOptionRequest(url, actionGA, categoryGA) {
    this.$gaService.event(actionGA, categoryGA);
    window.open(url, "_blank");
  }

  fillNumbersCities() {
    this.$gaService.event('Open-Numbers', 'Numbers');
    let contentTable = '';
    let pbx = [];
    let whatsapp = [];
    this.cities.forEach((city) => {
      city.numberContacts.forEach(numberContact => {
        if (numberContact.type === 'PBX') {
          pbx.push(numberContact.phone);
        } else if (numberContact.type === 'WHATSAPP') {
          whatsapp.push(numberContact.phone);
        }
      });
      contentTable += this.fillTable(city.name.replace(" ", ""), pbx, whatsapp);
      pbx = [];
      whatsapp = [];
    });
    const selectCityPhone = (document.getElementById('city') as HTMLInputElement).value;
    document.getElementById('modal-body-phones').innerHTML = contentTable;
    if (selectCityPhone !== '') {
      this.changeCity(selectCityPhone);
    }
    document.getElementById('openModalPhones').click();
  }

  alertMessagesAlertify(address, cityLocalized) {
    cityLocalized = cityLocalized.trim();
    let selected = '';
    let htmlOptionsSelect = '<option value=\"none\">Seleccione tu ciudad</option>';
    this.cityWhatsapp.forEach(city => {
      city === cityLocalized ? selected = 'selected' : selected = '';
      htmlOptionsSelect += '<option value=\"' + city + '\" ' + selected + '>' + city + '</option>';
    });
    let value = '';
    address !== undefined ? value = 'value=\"' + address + '\"' : value = '';
    alertify.confirm('Dirección <br> <input class="form-control" id="addressToSend" ' + value +
      ' type="text" placeholder="Ingrese su dirección" required><br>' +
      '<select id="cityWhatsappSelected" class="custom-select" style="display: block">' + htmlOptionsSelect +
      '</select>', () => {
        const citySelected = (document.getElementById('cityWhatsappSelected') as HTMLInputElement).value;
        let phone;
        if (citySelected !== 'none') {
          phone = (this.getWhatsappNumber(citySelected.replace(' ', '')));
          phone = phone.substr(1, phone.length);
        }
        alertify.set('notifier', 'position', 'top-center');
        if (!phone) {
          alertify.error('Por favor, seleccione una ciudad. Gracias</h5>');
        } else if (((document.getElementById('addressToSend') as HTMLInputElement).value).length < 6) {
          alertify.error('Por favor, ingrese una dirección correcta. Gracias</h5>');
        } else {
          this.$gaService.event('Open-Whatsapp', 'Whatsapp');
          location.href =
            'https://api.whatsapp.com/send?phone=' + phone.replace(' ', '') +
            '&text=Deseo%20solicitar%20un%20taxi%20a%20la%20siguiente%20dirección:%20*' +
            (document.getElementById('addressToSend') as HTMLInputElement).value + '*';
        }
      }).set('labels', { ok: 'Solicitar Taxi', cancel: 'Cancelar' }).setHeader('Seleccionar Ciudad');
  }

  getWhatsappNumber(cityToSearch) {
    for (let i = 0; i < this.cityWhatsapp.length; i++) {
      if (this.cityWhatsapp[i] === cityToSearch) {
        return this.whatsappNumbers[i];
      }
    }
  }

  fillTable(city, phone, whatsapp) {
    let sizePhones = phone.length;
    let sizeWhatsapp = whatsapp.length;
    let table = '<div hidden id="' + city + 'Div"><table style="width: 100%;">';
    const max = Math.max(sizePhones, sizeWhatsapp);
    for (let i = 0; i < max; i++) {
      table += '<tr>';
      if (sizePhones > 0) {
        table += '<td class="colPhones">' + phone[i] + '</td>'; sizePhones--;
      } else {
        table += '<td class="colPhones"></td>';
      }
      if (sizeWhatsapp > 0) {
        table += '<td class="colPhones">' + whatsapp[i] + '</td>'; sizeWhatsapp--;
      } else {
        table += '<td class="colPhones"></td>';
      }
      table += '</tr>';
    }
    return table + '</table></div>';
  }

  getInfocities() {
    this.homeService.getCities().subscribe(response => {
      this.cities = response;
      let nWhatsapp = '';
      if (this.whatsappNumbers.length === 0) {
        response.forEach((city) => {
          if (city.numberContacts.length > 0) {
            city.numberContacts.forEach((numberContact) => {
              if (numberContact.type === 'WHATSAPP') {
                nWhatsapp = numberContact.phone;
              }
            });
            if (nWhatsapp !== '') {
              this.cityWhatsapp.push(city.name);
              this.whatsappNumbers.push(nWhatsapp);
              nWhatsapp = '';
            }
          }
        });
      }
    }, () => {
      alertify.set('notifier', 'position', 'top-center');
      alertify.error('Lo sentimos, los números de contacto no están disponibles. Inténtalo más tarde');
      return null;
    });
  }

  changeCity(city) {
    this.cities.forEach((element) => {
      if (element.name === city) {
        console.log('#' + element.name + 'Div')
        $('#' + element.name.replace(" ","") + 'Div').attr('hidden', false);
      } else {
        $('#' + element.name.replace(" ","") + 'Div').attr('hidden', true);
      }
    });
  }
}
