
export const environment = {
  version: '1.0.0',
  name: 'landing-page-new',
  production: false,
  server: 'https://user.apismobility.info/',
  driverServer: 'https://driver.apismobility.info/',
  controlPanel: 'https://controlpanel.apismobility.info/',
  taxiaPlus: 'https://apitaxiaplus.apismobility.info/',
  apiLocations: 'https://apilocations.apismobility.info/',
  codeGA: 'UA-150355748-1',
  // tslint:disable-next-line:max-line-length
  accessTokenTaxiaPlus: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7InVpZCI6MTEyMTgyMjU2M30sImlhdCI6MTU2OTg2MzU2MSwiZXhwIjoxNTY5OTQ5OTYxfQ.Z4tXudnfzfeBcwbUG9XKcJ351_k23aiCYnyHQSqT8ig'
};
