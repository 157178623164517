import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '@home/home.component';
import { NotFoundComponent } from '@notFound/not-found.component';
import { EnterprisesComponent } from '@enterprises/enterprises.component';
import { DriversComponent } from '@drivers/drivers.component';
import { UsersComponent } from '@users/users.component';
import { SocialComponent } from '@social/social.component';
import { ContactComponent } from '@contact/contact.component';
import { TermsComponent } from '@terms/terms.component';
import { RestorePasswordComponent } from '@password/restore-password.component';
import { UnsubscribeComponent } from '@unsubscribe/unsubscribe.component';
import { HomeTaxiaPlusComponent } from '@taxia-plus/home/home.component';
import { PreRegisterComponent } from '@taxia-plus/pre-register/pre-register.component';
import { ShareComponent } from '@share/share.component';
import { PositionComponent } from '@position/position.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'services/company', component: EnterprisesComponent },
  { path: 'driver/howto', component: DriversComponent },
  { path: 'user/howto', component: UsersComponent },
  { path: 'press', component: SocialComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'password/restore/:token', component: RestorePasswordComponent },
  { path: 'unsubscribe', component: UnsubscribeComponent },
  { path: 'taxiaplus', component: HomeTaxiaPlusComponent },
  { path: 'pre_register_admin', component: PreRegisterComponent },
  { path: 'share/:token', component: ShareComponent },
  { path: 'position/:token', component: PositionComponent },
  { path: '**', redirectTo: '404',  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
