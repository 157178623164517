import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprises',
  templateUrl: './enterprises.component.html',
  styleUrls: ['./enterprises.component.css']
})
export class EnterprisesComponent implements OnInit {

  screenWidth = screen.width;

  constructor() { }

  ngOnInit() {
  }

}
