import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

declare const $: any;
@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit {

  animating = false;

  constructor(
    protected $gaService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.setSlider();
    // update arrows visibility and detect which section is visible in the viewport
    $(window).on('scroll resize', () => {
      (!window.requestAnimationFrame) ? this.setSlider() : window.requestAnimationFrame(this.setSlider.bind(this));
    });

    // move to next/previous using the keyboards
    $(document).keydown((event) => {
      if ( event.keyCode === 38 ) {
        this.prevSection();
        event.preventDefault();
      } else if ( event.keyCode === 40 ) {
        this.nextSection();
        event.preventDefault();
      }
    });
  }

  setSlider() {
    this.checkNavigation();
    this.checkVisibleSection();
  }

  // go to next section
  nextSection() {
    if (!this.animating) {
      if ($('.is-visible[data-type="slider-item"]').next().length > 0) {
        this.smoothScroll($('.is-visible[data-type="slider-item"]').next());
      }
    }
  }

  // go to previous section
  prevSection() {
    if (!this.animating) {
      const prevSection = $('.is-visible[data-type="slider-item"]');
      if (prevSection.length > 0 && $(window).scrollTop() !== prevSection.offset().top) {
        this.smoothScroll(prevSection);
      } else if (prevSection.prev().length > 0 && $(window).scrollTop() === prevSection.offset().top) {
        this.smoothScroll(prevSection.prev('[data-type="slider-item"]'));
      }
    }
  }
  // update the visibility of the navigation arrows
  checkNavigation() {
    ( $(window).scrollTop() < $(window).height() / 2 ) ?
      $('.cd-vertical-nav .cd-prev').addClass('inactive') : $('.cd-vertical-nav .cd-prev').removeClass('inactive');
    ( $(window).scrollTop() > $(document).height() - 3 * $(window).height() / 2 ) ?
      $('.cd-vertical-nav .cd-next').addClass('inactive') : $('.cd-vertical-nav .cd-next').removeClass('inactive');
  }

  // detect which section is visible in the viewport
  checkVisibleSection() {
    const scrollTop = $(window).scrollTop();
    const windowHeight = $(window).height();

    const group = document.getElementsByClassName('slider-item');
    Array.prototype.forEach.call(group, (item) => {
      const offset = scrollTop - item.offsetTop;
      // add/remove .is-visible class if the section is in the viewport - it is used to navigate through the sections
      ( offset >= 0 && offset < windowHeight ) ? item.classList.add('is-visible') : item.classList.remove('is-visible');
    });
  }

  smoothScroll(target) {
    this.animating = true;
    $('body,html').animate({scrollTop: target.offset().top}, 500, () => { this.animating = false; });
  }

  openDownload(url, actionGA, categoryGA) {
    this.$gaService.event(actionGA, categoryGA);
    window.open(url, "_blank");
  }

}
