import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RestorePasswordService {

  headers;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
    const headers = new HttpHeaders(
      {'x-citytaxi-application-id': 'citytaxi',
      'x-citytaxi-usercode-token': this.activatedRoute.snapshot.paramMap.get('token')}
    );
    this.headers = {headers};
  }

  restore(data): Observable<any> {
    return this.http.put(environment.server + 'updatepassword', {password: data}, this.headers).pipe(map(res => res));
  }
}
