import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PreRegisterService {
  headers;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      'access-token': environment.accessTokenTaxiaPlus
    });
  }

  getCheckDigit(nit): Observable<any> {
    return this.http.get(environment.taxiaPlus + 'check/' + nit).pipe(map(res => res));
  }

  saveRegister(data): Observable<any> {
    return this.http.post(environment.taxiaPlus + 'request/partner', data, this.headers).pipe(map(res => res));
  }

  getCategories(): Observable<any> {
    return this.http.get(environment.taxiaPlus + 'categories').pipe(map(res => res));
  }

  getCities(): Observable<any> {
    return this.http.get(environment.taxiaPlus + 'domains').pipe(map(res => res));
  }
}
