import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ShareService } from '@share/share.service';
import { SocketService } from '@share/socket.service';
import * as mapboxgl from 'mapbox-gl';
declare const mapboxgl: any;

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css'],
  providers: [ShareService, SocketService],
})
export class ShareComponent implements OnInit {

  goToBottom(){
    window.scrollTo({
      top: 700,
      left: 700,
      behavior: 'smooth'
    });
  }

  data: any;
  style = 'mapbox://styles/mapbox/streets-v11';
  map;
  infoService;
  marker;

  constructor(private dataService: ShareService, private socketService: SocketService) { }

  ngOnInit() {
    this.dataService.get_Data().subscribe(res => {
      this.data = res;
      this.infoService = {
        driver: res.driver,
        vehicle: res.vehicle,
        service: res.service,
      };
      if (!this.infoService.driver.photo) {
        this.infoService.driver.photo = '../../assets/images/profile.jpg';
      }
      const { lat, lng, device } = this.data;
      this.createMap(lat, lng);
      this.socketEvents(device, this.map);
    }, () => {
      window.location.href = '/404';
    });
  }

  createMap(lat, lng) {
    const options = {
      container: 'map',
      style: this.style,
      zoom: 15,
      center: [lng, lat],
      disableDefaultUI: true,
      navigationControl: false,
      mapTypeControl: false
    };
    mapboxgl.accessToken = 'pk.eyJ1IjoiZGllZ29nb21leiIsImEiOiJjam5qaW1xeXMwenNsM2tueXFiZWZ1andiIn0.suJs58a1pOpL4c-rQzU2VA';
    this.map = new mapboxgl.Map(options);
    this.printMarker('user', lng, lat, this.map);
  }

  socketEvents(device, map) {
    this.socketService.connect(device);
    this.socketService.subscribeToEvents()
      .subscribe((coordinates: any) => {
        this.printMarker('marker', coordinates.longitude, coordinates.latitude, map);
      });
  }

  printMarker(tipo, lng, lat, map) {
    if (this.marker) {
      this.marker.remove();
    }
    const el = document.createElement('div');
    el.className = tipo;
    this.marker = new mapboxgl.Marker(el)
      .setLngLat([lng, lat])
      .addTo(map);
    if (tipo === 'user') {
      this.marker = undefined;
    }
  }
}
