import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(
    protected $gaService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
  }

  openWhatsapp(number, actionGA, categoryGA) {
    this.$gaService.event(actionGA, categoryGA);
    window.open(`https://api.whatsapp.com/send?phone=57${number}`);
  }

}
